<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ purchasesOrder.name }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="
              purchasesOrder.status === ORDER_STATUS_DRAFT &&
              $currentUserCan($permissions.PERM_DELETE_PURCHASES_ORDERS)
            "
            @click="deletePurchasesOrder"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="
              purchasesOrder.status === ORDER_STATUS_DRAFT &&
              $currentUserCan($permissions.PERM_EDIT_PURCHASES_ORDERS)
            "
            @click="editPurchasesOrder"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ purchasesOrder.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("PURCHASES_ORDERS.EXPIRATION_TIME") }}</dt>
          <dd>
            {{ purchasesOrder.expiration_time | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd>
            <locations :locations="purchasesOrder.allowedLocations" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="purchasesOrder.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.STATUS") }}</dt>
          <dd>
            <purchases-order-status-badge
              :purchasesOrder="purchasesOrder"
              :advanced="true"
            />
          </dd>
        </dl>

        <dl class="row" v-if="purchasesOrder.purchasesInvoice">
          <dt>{{ $t("COMMON.PURCHASES_INVOICE") }}</dt>
          <dd>
            <object-link :object="purchasesOrder.purchasesInvoice" />
            <purchases-invoice-status-badge
              :purchasesInvoice="purchasesOrder.purchasesInvoice"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ISSUER") }}</dt>
          <dd>
            <object-link :object="purchasesOrder.issuer" />
          </dd>
        </dl>

        <dl class="row" v-if="purchasesOrder.destinationWarehouse">
          <dt>{{ $t("COMMON.WAREHOUSE") }}</dt>
          <dd>
            <object-link :object="purchasesOrder.destinationWarehouse" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.TOTAL") }}</dt>
          <dd v-if="purchasesOrder.pricing">
            <span> {{ $formatCurrency(purchasesOrder.pricing.total) }} </span>
          </dd>
        </dl>

        <dl class="row">
          <dt v-if="purchasesOrder.excerpt">
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd v-if="purchasesOrder.excerpt">
            <div v-html="purchasesOrder.excerpt"></div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import PurchasesInvoiceStatusBadge from "../../PurchasesInvoiceManagement/partials/PurchasesInvoiceStatusBadge.vue";
import PurchasesOrderStatusBadge from "./PurchasesOrderStatusBadge.vue";

import { ORDER_STATUS_DRAFT } from "@/constants/orders";

export default {
  name: "purchasesOrder-view-global",

  components: {
    PurchasesOrderStatusBadge,
    PurchasesInvoiceStatusBadge,
  },

  props: ["purchasesOrder"],

  data() {
    return {
      ORDER_STATUS_DRAFT,
    };
  },

  computed: {},

  created() {},

  methods: {
    editPurchasesOrder() {
      this.$emit("onEditPurchasesOrder", purchasesOrder);
    },
    deletePurchasesOrder() {
      this.$emit("onDeletePurchasesOrder", purchasesOrder);
    },
  },

  mounted() {},

  watch: {
    purchasesOrder(purchasesOrder) {},
  },
};
</script>
